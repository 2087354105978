/* eslint-disable @typescript-eslint/member-ordering,no-trailing-spaces */
// noinspection JSUnusedGlobalSymbols

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { DashboardPianiActions, DashboardPianiSelectors } from '@store/dashboard-piani-store/index';
import { mergeMap, of, switchMap } from 'rxjs';
import { ApiDashboardPianiService } from '@piani/services/api-dashboard-piani.service';
import { buffer, catchError, debounceTime, filter, map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class DashboardPianiEffects {
    private timer: number;

    constructor( private actions$: Actions, private store: Store, private api: ApiDashboardPianiService, private toaster: ToastrService ) {
    }

    /**
     * Reload dashboard data from backend
     */
    loadDashboard = createEffect( () =>
        this.actions$.pipe(
            ofType( DashboardPianiActions.loadDashboard, DashboardPianiActions.valueUpdated, DashboardPianiActions.changeSelectedCategory, DashboardPianiActions.changeSelectedZone ),
            tap( () => ( this.timer = Date.now() ) ),
            concatLatestFrom( () => this.store.select( DashboardPianiSelectors.selectDashboardFilter ) ),
            switchMap( ( [ , f ] ) =>
                this.api.getDrivers( f.idCategory, f.idZone ?? 0, f.fy ).pipe(
                    map( res => DashboardPianiActions.dashboardLoaded( { elements: res } ) ),
                    tap( () => console.log( `Dashboard loaded in ${ Date.now() - this.timer }ms` ) ),
                    catchError( err => {
                        console.error( 'Error in loading dashboard piani', err );
                        return of( DashboardPianiActions.dashboardLoadFailure() );
                    } )
                )
            )
        )
    );

    /**
     * Update a value in the backend.
     */
    updateValue = createEffect( () => {
        const bouncer = this.actions$.pipe( ofType( DashboardPianiActions.updateValue ), debounceTime( 2000 ) );
        return this.actions$.pipe(
            ofType( DashboardPianiActions.updateValue ),
            buffer( bouncer ),
            concatLatestFrom( () => this.store.select( DashboardPianiSelectors.selectDashboardFilter ) ),
            mergeMap( ( [ v, f ] ) =>
                this.api.updateMultipleValues( f.idCategory, f.idZone, f.fy, v ).pipe(
                    map( res => DashboardPianiActions.dashboardLoaded( { elements: res } ) ),
                    catchError( err => {
                        console.error( 'Error in updating value', err );
                        return of( DashboardPianiActions.valueUpdateFailure() );
                    } )
                )
            )
        );
    } );

    /**
     * Reload forecast data from backend
     */
    loadForecast = createEffect( () =>
        this.actions$.pipe(
            ofType( DashboardPianiActions.loadForecast, DashboardPianiActions.forecastValueUpdated, DashboardPianiActions.changeSelectedForecastZone,
                DashboardPianiActions.changeSelectedForecastMonth, DashboardPianiActions.changeSelectedForecastFilter, DashboardPianiActions.reloadForecast ),
            concatLatestFrom( () => this.store.select( DashboardPianiSelectors.selectForecastFilter ).pipe( filter( f => !!f.idMonth && !!f.idZone ) ) ),
            switchMap( ( [ , f ] ) =>
                this.api.getForecastDriver( f.idZone, f.fy, f.idMonth ).pipe(
                    map( res => DashboardPianiActions.forecastLoaded( { elements: res } ) ),
                    catchError( err => {
                        console.error( 'Error in loading forecast piani', err );
                        return of( DashboardPianiActions.forecastLoadFailure() );
                    } )
                )
            )
        )
    );

    /**
     * Update multiple forecast values in the backend.
     */
    /*
    updateForecastValue = createEffect( () => {
        const bouncer = this.actions$.pipe( ofType( DashboardPianiActions.updateForecastValue ), debounceTime( 2000 ) );
        return this.actions$.pipe(
            ofType( DashboardPianiActions.updateForecastValue ),
            buffer( bouncer ),
            map( values => values.map( vl => vl.value ) ),
            concatLatestFrom( () => this.store.select( DashboardPianiSelectors.selectForecastFilter ) ),
            mergeMap( ( [ v, f ] ) =>
                this.api.updateMultipleForecastValues( f.idZone, f.fy, v ).pipe(
                    map( res => DashboardPianiActions.forecastLoaded( { elements: res } ) ),
                    catchError( err => {
                        console.error( 'Error in updating forecast value', err );
                        return of( DashboardPianiActions.forecastValueUpdateFailure() );
                    } )
                )
            )
        );
    } );
    */

    saveForecastValues = createEffect( () => this.actions$.pipe(
        ofType( DashboardPianiActions.saveForecastValues ),
        concatLatestFrom( () => [
            this.store.select( DashboardPianiSelectors.selectForecastValuesToBeSaved ),
            this.store.select( DashboardPianiSelectors.selectForecastFilter ),
        ] ),
        mergeMap( ( [ _, v, f ] ) =>
            this.api.updateMultipleForecastValues( f.idZone, f.idMonth, f.fy, v ).pipe(
                map( res => DashboardPianiActions.forecastLoaded( { elements: res } ) ),
                catchError( err => {
                    console.error( 'Error in updating forecast value', err );
                    return of( DashboardPianiActions.forecastValueUpdateFailure() );
                } )
            )
        )
    ) );

    /**
     * Return a request based on zone and month. It will be created if it doesn't exist.
     */
    updateForecastRequest = createEffect( () =>
        this.actions$.pipe(
            ofType( DashboardPianiActions.changeSelectedForecastZone, DashboardPianiActions.changeSelectedForecastMonth, DashboardPianiActions.changeSelectedForecastFilter,
                DashboardPianiActions.forecastLoaded ),
            concatLatestFrom( () => this.store.select( DashboardPianiSelectors.selectForecastFilter ).pipe( filter( f => !!f.idMonth && !!f.idZone ) ) ),
            switchMap( ( [ , f ] ) => {
                DashboardPianiActions.loadForecastRequest();

                return this.api.getRequest( f.idZone, f.idMonth ).pipe(
                    map( forecastRequest => DashboardPianiActions.forecastRequestLoaded( { forecastRequest } ) ),
                    catchError( err => {
                        console.error( 'Error in getting forecast request', err );
                        return of( DashboardPianiActions.forecastRequestLoadFailure() );
                    } )
                );
            } )
        )
    );

    /**
     * Mark a request as requested for approval. It returns the new request and put it in the store.
     */
    forecastRequestApproval = createEffect( () =>
        this.actions$.pipe(
            ofType( DashboardPianiActions.forecastApprovalRequest ),
            concatLatestFrom( () => this.store.select( DashboardPianiSelectors.selectForecastFilter ).pipe( filter( f => !!f.idMonth && !!f.idZone ) ) ),
            switchMap( ( [ , f ] ) =>
                this.api.forecastRequestApproval( f.idZone, f.idMonth ).pipe(
                    map( forecastRequest => DashboardPianiActions.forecastRequestLoaded( { forecastRequest } ) ),
                    catchError( err => {
                        console.error( 'Error in approval forecast request', err );
                        this.toaster.error( 'Errore nella richiesta di approvazione' );
                        return of( DashboardPianiActions.forecastRequestApprovalFailure() );
                    } )
                )
            )
        )
    );

    /**
     * Load the approval requests
     */
    forecastApprovalRequests = createEffect( () =>
        this.actions$.pipe(
            ofType( DashboardPianiActions.loadApprovalRequests, DashboardPianiActions.forecastRequestLoaded ),
            switchMap( () =>
                this.api.getApprovalRequests().pipe(
                    map( approvalRequests => DashboardPianiActions.approvalRequestsLoaded( { approvalRequests } ) ),
                    catchError( err => {
                        console.error( 'Error in getting approval requests', err );
                        return of( DashboardPianiActions.approvalRequestsLoadFailure() );
                    } )
                )
            )
        )
    );

    /**
     * Load the refused requests
     */
    forecastRefusedRequests = createEffect( () =>
        this.actions$.pipe(
            ofType( DashboardPianiActions.loadRefusedRequests, DashboardPianiActions.forecastRequestLoaded ),
            concatLatestFrom( () => this.store.select( DashboardPianiSelectors.selectForecastZone ).pipe( filter( z => !!z ) ) ),
            switchMap( ( [ , idZone ] ) =>
                this.api.getRefusedRequests( idZone ).pipe(
                    map( refusedRequests => DashboardPianiActions.refusedRequestsLoaded( { refusedRequests } ) ),
                    catchError( err => {
                        console.error( 'Error in getting refused requests', err );
                        return of( DashboardPianiActions.refusedRequestsLoadFailure() );
                    } )
                )
            )
        )
    );

    /**
     * Mark a request as approved. It returns the new request and put it in the store.
     */
    forecastRequestApprove = createEffect( () =>
        this.actions$.pipe(
            ofType( DashboardPianiActions.forecastApproveRequest ),
            concatLatestFrom( () => this.store.select( DashboardPianiSelectors.selectForecastFilter ).pipe( filter( f => !!f.idMonth && !!f.idZone ) ) ),
            switchMap( ( [ , f ] ) =>
                this.api.forecastRequestApprove( f.idZone, f.idMonth ).pipe(
                    map( forecastRequest => DashboardPianiActions.forecastRequestLoaded( { forecastRequest } ) ),
                    tap( () => this.toaster.success( 'Richiesta approvata' ) ),
                    catchError( err => {
                        console.error( 'Error in approve forecast request', err );
                        this.toaster.error( 'Errore nell\'approvazione della richiesta' );
                        return of( DashboardPianiActions.forecastRequestApproveFailure() );
                    } )
                )
            )
        )
    );
}
